import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import storage from '../../helpers/storage'
import { IBlogContext, IResourceContext, ISidebarProps, IUserContext, IVaceContext } from '../../utils/types'
import Axios from 'axios';
import UserContext from '../../context/user/userContext'
import { useAccountCheck, useNetworkDetect, usePageRedirect } from '../../helpers/hooks';
import body from '../../helpers/body'
import { UserType } from '../../utils/enums';
import VaceContext from '../../context/vace/vaceContext';
import AccountModal from '../app/wallet/BankAccModal';
import SidebarWallet from '../app/wallet/SidebarWallet';
import IsActiveModal from '../app/IsActiveModal';
import ResourceContext from '../../context/resource/resourceContext';

const SideBar = ({ collapsed, barCollapsed, expandSidebar }: ISidebarProps) => {

    const navigate = useNavigate()
    const cookie = new Cookies();

    const userContext = useContext<IUserContext>(UserContext);
    const vaceContext = useContext<IVaceContext>(VaceContext);
    const resourceContext = useContext<IResourceContext>(ResourceContext)

    const [active, setActive] = useState<string | null>('home')
    const [sub, setSub] = useState<string | null>('');
    const [sidebox, setSidebox] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [showActive, setShowActive] = useState<boolean>(false)

    useEffect(() => {

        body.changeBackground('bg-mint');

        // call init functions
        initPage();

    }, [vaceContext.business])

    // process graph data
    useEffect(() => {

        if (vaceContext.overview.products && vaceContext.overview.paymentLinks && vaceContext.overview.transactions) {

            vaceContext.setPieData({
                original: vaceContext.graph,
                products: vaceContext.overview.products.total,
                transactions: vaceContext.overview.transactions.total,
                paymentLinks: vaceContext.overview.paymentLinks.total,
            })

        }

    }, [vaceContext.graph])

    useNetworkDetect();
    usePageRedirect(["business", "superadmin"])
    useAccountCheck(() => { setShowActive(true) });

    const initPage = async () => {

        // set user type
        userContext.setUserType(userContext.getUserType());

        // activate submenu
        if (barCollapsed === true) {
            userContext.setSidebar(false, '')
        } else {

            if (collapsed === true) {
                userContext.setSidebar(false, '')
            } else {

                const sb = storage.fetch('sub-menu')

                if (sb) {
                    userContext.setSidebar(sb.active, sb.label)
                }

            }

        }

        setActive(storage.fetchLegacy('sb-menu') ? storage.fetchLegacy('sb-menu') : 'home');
        setSub(storage.fetchLegacy('sub-link') ? storage.fetchLegacy('sub-link') : '');

        if (body.isObjectEmpty(userContext.user) === true) {
            userContext.getUser(storage.getUserID());
        }

        if (body.isObjectEmpty(vaceContext.business) === true) {
            vaceContext.getBusiness(storage.getUserID())
        }

        if (body.isObjectEmpty(userContext.KYBData) === true) {
            userContext.getCompliance({ id: storage.getUserID(), type: 'kyb' })
        }

        // get overview
        vaceContext.getOverview();

        // get banks
        if(body.isArrayEmpty(vaceContext.banks) === true){
            resourceContext.getBanks({ limit: 9999, page: 1, order: 'desc', mapped: true })
        }

    }

    const goToMenu = (e: any, url: string, p: string) => {
        if (e) { e.preventDefault() }
        setActive(p);

        storage.keepLegacy('sb-menu', p);
        storage.delete('sub-menu', false);
        userContext.setSidebar(false, '')

        navigate(url)
    }

    const goToSub = (e: any, url: string, t: string) => {
        if (e) { e.preventDefault() }
        setSub(t);
        storage.keepLegacy('sub-link', t);
        navigate(url);
    }

    const redirectToLogin = () => {

        const ut = cookie.get("userType");

        if (!storage.checkToken() && !storage.checkUserID()) {
            logout()
        } else if (ut === '' || ut === undefined || ut === null) {
            logout()
        }
    }

    const logout = async (e: any = null) => {

        if (e) e.preventDefault();

        storage.clearAuth();
        localStorage.clear();
        navigate('/login');

        // remove cookies
        cookie.remove('token');
        cookie.remove('userType');
        await Axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, storage.getConfig());
    }

    const toggleSub = (e: any, lb: string, url: string = '') => {

        if (e) { e.preventDefault() }

        if (userContext.sidebar.active && userContext.sidebar.label === lb) {
            userContext.setSidebar(false, '')
            storage.delete('sub-menu')
        } else {

            userContext.setSidebar(true, lb)
            setActive(lb);

            // local storage
            storage.keepLegacy('sb-menu', lb)
            storage.keep('sub-menu', { active: true, label: lb })
        }

        if (url) { navigate(url) }

        if (collapsed && barCollapsed) {
            expandSidebar(e)
        }

    }

    const closeSub = (e: any) => {
        if (e) { e.preventDefault() }

        userContext.setSidebar(false, '')
        storage.delete('sub-menu', false)
    }

    const toggleSideDrop = (e: any) => {
        if (e) { e.preventDefault() }
        setSidebox(!sidebox);
    }

    const toggleAccount = (e: any) => {
        if (e) e.preventDefault()
        setShowAddModal(!showAddModal)
    }

    return (
        <>

            <div className='ui-monitor'>
                <div className='d-flex'>
                    <div />
                    <div className='ml-auto'>
                        <Link to='/' className='pullin--btn onblack'>
                            <span
                                className='fe fe-arrow-left fs-20'
                                style={{ color: '#2F80ED' }}
                            />
                        </Link>
                    </div>
                </div>
            </div>

            <section id="ui-sidebar" className={`ui-sidebar ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'}`}>

                {/* secondary */}
                <div className={`ui-sidebar-secondary head-shift ${userContext.sidebar.active ? 'open' : 'close'}`}>

                    <div className={`bar-head`}>

                        <Link to={``} className='' style={{ color: "#320688" }}>
                            <span className='font-aeonik-bold fs-11 ui-upcase pdr'>SUB MENU</span>
                        </Link>
                        <Link onClick={(e) => closeSub(e)} to="" className='ml-auto ui-relative' style={{ opacity: '0.75', top: '1px' }}><span className='fe fe-x fs-15' style={{ color: '#320688' }}></span></Link>

                    </div>

                    <div className='bar-body'>

                        <ul className='links'>

                            {
                                userContext.sidebar.label === 'account' &&
                                <>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/account/profile', 'profile')} to="/dashboard/account/profile">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'profile' ? 'brand-purple' : ''}`}>Profile</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'profile' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/account/compliance', 'compliance')} to="/dashboard/account/compliance">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'compliance' ? 'brand-purple' : ''}`}>Compliance</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'compliance' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/account/banks', 'banks-cards')} to="/dashboard/account/banks">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'banks-cards' ? 'brand-purple' : ''}`}>Banks & Cards</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'bank-cards' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/account/preferences', 'preferences')} to="/dashboard/account/preferences">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'preferences' ? 'brand-purple' : ''}`}>Preferences</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'preferences' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>

                                </>
                            }

                            {
                                userContext.sidebar.label === 'settings' &&
                                <>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/settings', 'general')} to="/dashboard/settings">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'general' ? 'brand-purple' : ''}`}>General</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'general' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/settings/security', 'security')} to="/dashboard/settings/security">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'security' ? 'brand-purple' : ''}`}>Security</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'security' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/settings/webhook-key', 'webhook-key')} to="/dashboard/settings/webhook-key">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'webhook-key' ? 'brand-purple' : ''}`}>API Keys</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'webhook-key' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>

                                </>
                            }

                            {
                                userContext.sidebar.label === 'payments' &&
                                <>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/products', 'products')} to="/dashboard/products">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'products' ? 'brand-purple' : ''}`}>Products</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'products' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/payment-links', 'payment-links')} to="/dashboard/payment-links">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'payment-links' ? 'brand-purple' : ''}`}>Payment Links</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'payment-links' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/transactions', 'transactions')} to="/dashboard/transactions">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'transactions' ? 'brand-purple' : ''}`}>Transactions</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'transactions' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/subaccounts', 'subaccounts')} to="/dashboard/subaccounts">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'subaccounts' ? 'brand-purple' : ''}`}>Subaccounts</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'subaccounts' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/settlements', 'settlements')} to="/dashboard/settlements">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'settlements' ? 'brand-purple' : ''}`}>Settlements</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'settlements' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>

                                </>
                            }

                            {
                                userContext.sidebar.label === 'updates' &&
                                <>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/updates/new', 'what-new')} to="">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'what-new' ? 'brand-purple' : ''}`}>What's New</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'what-new' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/updates/feedback', 'feedback')} to="">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'feedback' ? 'brand-purple' : ''}`}>Feedback</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'feedback' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                </>
                            }

                            {
                                userContext.sidebar.label === 'support' &&
                                <>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/support/help', 'help')} to="">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'help' ? 'brand-purple' : ''}`}>Help</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'help' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, '/dashboard/support/contact', 'contact')} to="">
                                            <span className={`font-aeonik-medium fs-13 ${sub === 'contact' ? 'brand-purple' : ''}`}>Contact Us</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'contact' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                </>
                            }

                        </ul>
                    </div>


                </div>

                {/* primary */}
                <div id="ui-sidebar-primary" className={`ui-sidebar-primary ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'}`}>

                    <div id="ui-sidebar-primary-header" className="ui-sidebar-primary-header mrgb1">

                        <Link to="/dashboard"><img className="logo" width={180} src="../../../images/icons/icon@terra-logo.svg" alt="" /></Link>

                    </div>

                    <SidebarWallet toggleAccount={toggleAccount} />

                    <div className='ui-sidebar-primary-body'>

                        <div className="ui-separate-small ui-show-mobile-only"></div>

                        <ul id="ui-sidebar-primary-links" className={`ui-sidebar-primary-links primary-nav pdl0 pdb0`}>


                            {
                                userContext.getUserType() === UserType.BUSINESS &&
                                <>

                                    <li className={`nav-list ${active === 'home' ? 'active' : ''}`}>
                                        <Link onClick={(e) => goToMenu(e, '/dashboard', 'home')} to='' className='ui-icon-animate link' title='Home'>
                                            <span style={{ top: '1px', left: '2px' }} className={`ui-relative terra-board ts-icon ${active === 'home' ? 'active' : ''}`}>
                                                <i className='path1 fs-18'></i>
                                                <i className='path2 fs-18'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-4px', top: '1px' }} className={`lnk--text sb-text font-${active === 'home' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Dashboard
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={` nav-list ${active === 'txn' ? 'active' : ''}`}>
                                        <Link onClick={(e) => goToMenu(e, '/dashboard/transactions', 'txn')} to='' className='ui-icon-animate link' title='Transactions'>
                                            <span style={{ position: 'relative', left: '2px', top: '1px' }} className={`ui-relative terra-layers ts-icon ${active === 'txn' ? 'active' : ''}`}>
                                                <i className='path1 fs-21'></i>
                                                <i className='path2 fs-21'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-8px' }} className={`lnk--text sb-text font-${active === 'txn' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Transactions
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={` nav-list ${active === 'wallet' ? 'active' : ''}`}>
                                        <Link onClick={(e) => goToMenu(e, '/dashboard/wallet', 'wallet')} to='' className='ui-icon-animate link' title='Wallet'>
                                            <span style={{ position: 'relative', left: '2px', top: '1px', color: '#fff' }} className={`ui-relative terra-wallet ts-icon ${active === 'wallet' ? 'active' : ''}`}>
                                                <i className='path1 fs-20'></i>
                                                <i className='path2 fs-20'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-6px' }} className={`lnk--text sb-text font-${active === 'wallet' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Wallet
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={` nav-list ${active === 'payments' ? 'active' : ''}`}>
                                        <Link onClick={(e) => toggleSub(e, 'payments', '')} to='' className='ui-icon-animate link' title='Payments'>
                                            <span style={{ left: '2px' }} className={`ui-relative terra-card ts-icon ${active === 'payments' ? 'active' : ''}`}>
                                                <i className='path1 fs-19'></i>
                                                <i className='path2 fs-19'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-5px' }} className={`lnk--text sb-text font-${active === 'payments' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Payments
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={` nav-list ${active === 'invoices' ? 'active' : ''}`}>
                                        <Link onClick={(e) => goToMenu(e, '/dashboard/invoices', 'invoices')} to='' className='ui-icon-animate link' title='Invoices'>
                                            <span style={{ left: '2px' }} className={`ui-relative terra-invoice ts-icon ${active === 'invoices' ? 'active' : ''}`}>
                                                <i className='path1 fs-18'></i>
                                                <i className='path2 fs-18'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-4px' }} className={`lnk--text sb-text font-${active === 'invoices' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Invoices
                                            </span>
                                        </Link>
                                    </li>

                                    <div className='pdt pdb'>
                                        <div className="ui-line bg-terramilkpurple"></div>
                                    </div>

                                    <li className={` nav-list ${active === 'account' ? 'active' : ''}`}>
                                        <Link onClick={(e) => toggleSub(e, 'account', '')} to='' className='ui-icon-animate link' title='Account'>
                                            <span style={{ position: 'relative', left: '2px', top: '1px' }} className={`ui-relative terra-account ts-icon ${active === 'account' ? 'active' : ''}`}>
                                                <i className='path1 fs-21'></i>
                                                <i className='path2 fs-21'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-8px' }} className={`lnk--text sb-text font-${active === 'account' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Account
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={` nav-list ${active === 'settings' ? 'active' : ''}`}>
                                        <Link onClick={(e) => toggleSub(e, 'settings', '/dashboard/settings')} to='' className='ui-icon-animate link' title='Settings'>
                                            <span style={{ position: 'relative', left: '2px', top: '1px', }} className={`ui-relative terra-settings ts-icon ${active === 'settings' ? 'active' : ''}`}>
                                                <i className='path1 fs-21'></i>
                                                <i className='path2 fs-21'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-9px' }} className={`lnk--text sb-text font-${active === 'settings' ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                Settings
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={` nav-list ${active === 'apis' ? 'active' : ''}`}>
                                        <Link target='_blank' to='https://docs.terraswitching.com' className='ui-icon-animate link' title='APIs'>
                                            <span style={{ position: 'relative', left: '2px', top: '1px' }} className={`ui-relative terra-link ts-icon ${active === 'support' ? 'active' : ''}`}>
                                                <i className='path1 fs-19'></i>
                                                <i className='path2 fs-19'></i>
                                            </span>
                                            <span style={{ position: 'relative', left: '-8px' }} className='lnk--text sb-text font-aeonik-medium fs-14'>
                                                APIs
                                            </span>
                                        </Link>
                                    </li>

                                </>
                            }


                        </ul>

                        <ul id="ui-sidebar-primary-links" className={`ui-sidebar-primary-links`}>

                            <li className={`nav-list`}>
                                <Link onClick={(e) => logout(e)} to='' className='ui-icon-animate link' title='Logout'>
                                    <span style={{ top: '1px', left: '5px' }} className='ui-relative terra-power ts-icon'>
                                        <i className='path1 fs-20'></i>
                                        <i className='path2 fs-20'></i>
                                    </span>
                                    <span style={{ position: 'relative', left: '-9px' }} className='lnk--text sb-text font-aeonik-medium fs-14'>
                                        Logout
                                    </span>
                                </Link>
                            </li>

                        </ul>

                    </div>

                </div>

            </section>

            <AccountModal
                isShow={showAddModal}
                closeModal={toggleAccount}
                modalTitle="Account Details"
                flattened={true}
                slim="slim-slg"
            />

            <IsActiveModal
                isShow={showActive}
                closeModal={() => {}}
                modalTitle="Deactivated!"
                flattened={true}
                slim="slim"
            />

        </>
    )

}

export default SideBar

