import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import storage from '../../helpers/storage';
import { IModalProps, IUserContext, IVaceContext } from '../../utils/types';
import TerraIcon from '../partials/icons/TerraIcon';
import Axios from 'axios'

const IsActiveModal = ({ isShow, closeModal, modalTitle, flattened, stretch, slim }: Partial<IModalProps>) => {

  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate()
  const cookie = new Cookies();

  useEffect(() => {
    setStep(0);
  }, [])

  const closeX = (e: any = null) => {
    if (e) e.preventDefault();
    closeModal();

    setTimeout(() => {
      setStep(0)
    }, 400)
  }

  const logout = async (e: any = null) => {

    if (e) e.preventDefault();

    storage.clearAuth();
    localStorage.clear();
    navigate('/login');

    // remove cookies
    cookie.remove('token');
    cookie.remove('userType');
    await Axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, storage.getConfig());
  }

  return (
    <>

      <Modal show={isShow}
        onHide={() => closeX()}
        size="sm"
        fade={false}
        keyboard={false}
        aria-labelledby="medium-modal"
        centered
        className={`custom-modal send ${slim ? slim : ''} ${stretch ? 'stretched' : ''} ${flattened ? 'flat' : ''} lg`}
      >

        <Modal.Body className=''>


          <div className="d-flex">

            <div className="dm--dbx ui-full-bg-norm" style={{ backgroundImage: 'url("../../../images/assets/bg@auth01.jpg")' }}>
              <div className="dm--d">
                <div>
                  {/* <img src="../../../images/assets/i" alt="icon" /> */}
                </div>
              </div>
            </div>

            <div className="dm--body">

              <div className={`d-flex align-items-center mrgb0`}>

                <div className={`d-flex align-items-center`}>

                  <h2 className={`brand-purple mrgb0 font-aeonik-medium fs-16 fs-sm-13`}>
                    {modalTitle}
                  </h2>

                </div>

                <div className="ml-auto ui-hide">
                  <Link to="" onClick={(e) => closeX(e)} className="link-round sm ui-icon-animate bg-terralpurple">
                    <span className="fe fe-x fs-20 fs-sm-12 brand-purple"></span>
                  </Link>
                </div>

              </div>

              <div className="dm--ct mrgt1-mid">

                <div className='ui-text-center'>

                  <div className='link-round xlg'>
                    <TerraIcon
                      name='info'
                      paths={['path1', 'path2']}
                      clickable={false}
                      size={35}
                      className=''
                      isActive={false}
                    />
                  </div>

                </div>

                <div className='ui-text-center ui-line-height mrgt2 pdb1'>
                  <p className="mrgb2 font-aeonik fs-14 brand-purple-dark">Your business account has been deactivated on Terraswitch. Please contact support for more information on how to re-activate your account.</p>
                  <Link to="" onClick={(e) => logout(e)} className={`btn md stretch-md onwhite bg-purple ml-auto`}>
                    <span className='font-aeonik-bold fs-14'>Logout</span>
                  </Link>
                </div>

              </div>

            </div>

          </div>

        </Modal.Body>

      </Modal>

    </>
  )

}

export default IsActiveModal